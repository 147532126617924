'use client';

import { useSearchParams } from 'next/navigation';
import DynamicBody from '@/components/core/DynamicBody';
import { AppSupport, Footer } from '@/app/_components/platform';
import { FaqBanner, Faqs } from '@/app/components/shared';

import HeroWithCards from '@/components/platform/HeroWithCards';

import { ClientProvider } from '@/contexts/client.context';

import { products } from '@/utils/products';

const MainPage = ({ content }) => {
  const {
    body,
    app,
    bottomCTA,
    disclaimer,
    footnotes,
    faqs,
    contact,
    heroRolling,
    solutionConditions,
  } = content;

  const faqBanner = {
    title: 'Still have questions? Get in touch.',
  };

  const searchParams = useSearchParams();
  const paramsString = searchParams.toString();

  return (
    <>
      <ClientProvider
        defaultClient={{
          clientKey: null,
          onboardingUrl: '',
          institutionKey: null,
          launchDate: null,
          isActive: true,
          product: products.PLATFORM,
        }}
      >
        {heroRolling && (
          <HeroWithCards
            content={heroRolling}
            solutionConditions={solutionConditions}
            params={paramsString}
          />
        )}
        <DynamicBody body={body} isOrganicLP />
        {faqs && <Faqs content={faqs} />}
        <FaqBanner contact={contact} content={faqBanner} />
        {app && <AppSupport content={app} />}
        <Footer
          bottomCTA={bottomCTA}
          disclaimer={disclaimer}
          footnotes={footnotes}
          email={contact?.email}
          isOrganicLP
        />
      </ClientProvider>
    </>
  );
};

export default MainPage;
